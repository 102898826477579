import React from 'react';

const formCopyPTM = (
  <div>
    <h2>REQUISITOS PARA SER MIEMBRO DEL EQUIPO DE ORACIÓN</h2>
    <ul>
      <li>
        Estar de acuerdo con la Declaración de fe de la Billy Graham
        Evangelistic Association (BGEA).
      </li>
      <li>Asistir a la Clase de Vida y Testimonio Cristiano.</li>
      <li>Ingresar una solicitud.</li>
      <li>
        Envía una verificación de antecedentes penales gratuita (si tienes 18
        años o más).
      </li>
      <li>Presentar una recomendación pastoral.</li>
      <li>
        Comprometerte a orar con aquellos que tomen la decisión de seguir a
        Cristo en el God Loves You Tour.
      </li>
    </ul>
  </div>
);

export default formCopyPTM;
