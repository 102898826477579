import React from 'react';

const formCopyPTMEN = (
  <div>
    <h2>Prayer Team Member Requirements</h2>
    <ul>
      <li>
        Agree to the Billy Graham Evangelistic Association (BGEA) Statement of
        Faith.
      </li>
      <li>Attend one Christian Life and Witness Course.</li>
      <li>Submit an application.</li>
      <li>
        Submit a free background check (
        <strong>If 18 years of age or older</strong>)
      </li>
      <li>Provide a pastoral recommendation</li>
      <li>
        Commit to pray with those who make decisions for Christ at the God Loves
        You Tour
      </li>
    </ul>
  </div>
);

export default formCopyPTMEN;
