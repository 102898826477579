import formCopyPTM from "../../../../src/content/es/prayerTeamMember/formCopy";
import citiesES from "../../../../src/constants/citiesES";
import missionStatementCopy from "../../../../src/content/es/missionStatementAndRelease";
import confirmationCopyPTM from "../../../../src/content/es/prayerTeamMember/confirmationCopy";
import * as React from 'react';
export default {
  formCopyPTM,
  citiesES,
  missionStatementCopy,
  confirmationCopyPTM,
  React
};