import { useStaticQuery, graphql } from 'gatsby';

export const useQuery = () => {
  const props = useStaticQuery(graphql`
    query MyQuery {
      receptions: allMdx(
        filter: { frontmatter: { category: { eq: "reception" } } }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        nodes {
          body
          frontmatter {
            event {
              title
              time
              location
              address
              date
            }
            featuredImage
            region
            order
            tab
            title
            slug
          }
        }
      }
      tourDatesEn: allMdx(
        filter: {
          frontmatter: { category: { eq: "tour-date" } }
          fileAbsolutePath: { regex: "/en/" }
        }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        nodes {
          body
          frontmatter {
            event {
              title
              time
              location
              address
              date
            }
            order
            tab
            title
          }
        }
      }
      tourDatesEs: allMdx(
        filter: {
          frontmatter: { category: { eq: "tour-date" } }
          fileAbsolutePath: { regex: "/es/" }
        }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        nodes {
          body
          frontmatter {
            event {
              title
              time
              location
              address
              date
            }
            order
            tab
            title
          }
        }
      }
    }
  `);
  return props;
};
