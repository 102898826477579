import React from 'react';

const formCopyGMEN = (
  <div>
    <p>
      Thank you for your interest in serving as a part of an on-site team group
      for the God Loves You Frontera Tour! Please complete the form below as
      directed by your Group Leader, then continue to keep an eye on your e-mail
      for additional volunteer information and updates.
    </p>
    <br />
  </div>
);

export default formCopyGMEN;
