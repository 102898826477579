import React from 'react';

const confirmationCopyGM = () => (
  <div>
    <p>¡Ya casi terminas!</p>
    <p>
      ¡Gracias por registrarte para servir con un grupo en la gira God Loves You Tour! El siguiente paso es inscribirte en un turno para el día del evento. El responsable de tu grupo te comunicará cuándo y cómo inscribirte en el turno que te corresponda. Cuando te inscribas en un turno, utiliza el mismo nombre y correo electrónico que ingresaste en el formulario de voluntario.
    </p>
    <p>
      Antes del evento recibirás un kit de voluntario<strong>*</strong>, con el cual podrás invitar a la gente de tu comunidad a la gira God Loves You Frontera Tour. Si resides en los Estados Unidos, tu kit de voluntario será enviado por correo postal directamente al domicilio que incluiste en tu solicitud. Si resides en México, tu kit de voluntario será enviado a tu iglesia local y recibirás una notificación con información para que sepas cuándo puedes pases a recogerlo.
    </p>
    <p>
      <strong>
        *Los kits de voluntario dejarán de enviarse para distribución el 8 de febrero de 2024. Los kits de voluntario para quienes residen en los Estados Unidos dejarán de enviarse el 19 de febrero de 2024.
      </strong>
    </p>
    <p>
      Si tienes más preguntas, por favor contáctanos a{' '}
      <a href="mailto:GodLovesYouTourVolunteers@BillyGraham.org ">
        GodLovesYouTourVolunteers@BillyGraham.org
      </a>
      .
    </p>
    <p>
      Nota: Si eres menor de 18 años, la BGEA debe recibir el consentimiento de tus padres/tutores legales antes de que puedas recibir tu kit de voluntario y antes de que puedas prestar tus servicios el día del evento.
    </p>
  </div>
);

export default confirmationCopyGM;
