import React from 'react';
import { Block } from '@bgea-js/react-ui';

const confirmationCopyPTMEN = () => (
  <div>
    <Block>
      You have successfully submitted your application for the Prayer Team.
      Thank you, we are grateful for you! However, before your application can
      be considered for approval, you will need to complete the additional steps
      below.
    </Block>
    <Block textWeight="bold">Next Steps:</Block>
    <Block>
      <b>1. Complete a free background check:</b> Upon the submission of your
      prayer team application, you will receive an email from{' '}
      <a href="mailto:Webmaster@billygraham.email">
        Webmaster@billygraham.email
      </a>
      , containing the links to a free background check. If you are 18 years of
      age or older, please click on the link for your city if you are a citizen
      or permanent resident of the United States. Click on the link for Mexico
      if you are a citizen of Mexico.
    </Block>
    <Block>
      <b>2. Pastoral Recommendation:</b> An email has been sent to the pastor /
      church leader contact you provided on your application—they will need to
      reply to that email as soon as possible. Please reach out to your contact
      and ask them to respond in a timely manner.
    </Block>
    <Block>
      Please note: If you are under the age of 18, parent/legal guardian consent
      must be received by the Billy Graham Evangelistic Association (BGEA)
      before your volunteer kit* can be mailed and before you can serve as a
      Prayer Team member on the day of the event.
    </Block>
    <Block>
      If you are a resident of the United States, your volunteer kit will be
      mailed directly to the address provided on your volunteer form. If you are
      a resident of Mexico, your volunteer kit will be mailed to a local church
      for distribution and you will be notified by email when and where your kit
      can be picked up.
    </Block>
    <Block textWeight="bold">
      * Volunteer kits for Mexico residents will not be mailed for distribution
      after February 8, 2024. Volunteer kits for U.S. residents will not be
      mailed after February 19, 2024.
    </Block>
    <Block>
      If you have any questions, please call 888-958-6329 (Monday-Friday 8:30
      a.m.-4:30 p.m. EDT).
    </Block>
  </div>
);

export default confirmationCopyPTMEN;
