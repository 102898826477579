import React from 'react';
import { useLocation } from '@reach/router';

const VolTextIndividual = {
  en: 'Register as an Individual',
  es: 'REGISTRO PARA VOLUNTARIADO INDIVIDUAL',
};

const VolTextGroup = {
  en: 'Register as a Group Leader',
  es: 'REGISTRO PARA LÍDERES DE GRUPO',
};

const VolUrlInd = {
  en: '/en/individual-volunteer',
  es: '/es/voluntariado-individual',
};

const VolUrlGroup = {
  en: '/en/group-leader',
  es: '/es/voluntarios-lideres-de-grupo',
};

const VolunteerCTA = () => {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];

  return (
    <div className="content-wrapper" id="volunteer-cta">
      <section className="featured">
        <div className="featured-container">
          <div className="column">
            <a href={VolUrlInd[currentPathLanguage]}>
              <img
                src="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/09/frontera-greeter.jpg"
                alt=""
              />
              <div className="block teal">
                <h3>{VolTextIndividual[currentPathLanguage]}</h3>
              </div>
            </a>
          </div>
          {/* <div className="column">
            <a href={VolUrlGroup[currentPathLanguage]}>
              <img
                src="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/09/frontera-group.jpg"
                alt=""
              />
              <div className="block orange">
                <h3>{VolTextGroup[currentPathLanguage]}</h3>
              </div>
            </a>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default VolunteerCTA;
