import React from 'react';

const formCopyIYCEN = (
  <div>
    <p>Dear Pastor,</p>
    <p>
      Thank you for your interest in being involved in the Franklin Graham God
      Loves You Frontera Tour. Churches like yours play a vital role in the
      community and in the work of evangelism. We look forward to partnering
      with you for the sake of the Gospel.
    </p>
    <p>
      Fill out the form below so your church can learn more about praying,
      inviting those who don't yet know Jesus Christ, and attending the God
      Loves You Frontera Tour event near you.
    </p>
  </div>
);

export default formCopyIYCEN;
