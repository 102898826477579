import React from 'react';

const emailSubscribeConfirmationEN = () => (
  <div>
    <p>Get Email Updates About the God Loves You Frontera Tour.</p>
    <p>Thank You!</p>
    <p>Your submission is complete.</p>
  </div>
);

export default emailSubscribeConfirmationEN;
