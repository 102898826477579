import React from 'react';

const confirmationCopyIYC = () => (
  <div>
    <p>
      Gracias por tu interés en la gira God Loves You Frontera Tour. Hemos
      recibido tu solicitud. Revisa tu correo electrónico en los próximos días
      para recibir actualizaciones sobre la gira y sobre las formas en que
      puedes participar.
    </p>
    <p>
      Mientras tanto, visita nuestra página <a href="/es/recursos">Recursos</a>{' '}
      para descargar los materiales que te ayudarán a promover la gira God Loves
      You Frontera Tour tanto en tu iglesia como en tu comunidad. Encontrarás
      videos y diapositivas para descargar, así como recursos imprimibles en
      inglés y en español. También puedes solicitar invitaciones para los
      eventos de forma gratuita llamando al{' '}
      <a href="tel:1-800-801-3953">1-800-801-3953</a>.
    </p>
    <p>
      Además, antes de la gira se celebrarán varios eventos en los que usted y
      su iglesia pueden participar, como el Curso de Vida y Testimonio
      Cristiano, una clase gratuita de capacitación para quienes deseen formar
      parte del equipo de oración durante la gira. Estas clases están abiertas a
      todo público y no es necesario inscribirse. En esta capacitación de tres
      horas, los asistentes aprenderán a compartir su fe en Jesucristo con los
      demás de manera sencilla, así como a orar con aquellos que se pongan de
      pie para aceptarlo durante los eventos de la gira.
    </p>
    <p>
      La Noche de Oración y Adoración también llegará a tu ciudad en enero. Se
      trata de una velada en la que las iglesias de tu comunidad se reunirán
      para orar por el avivamiento de tu ciudad. Esta noche también contará con
      un tiempo de adoración dirigido por Job González. Esta es también una
      oportunidad para recoger material promocional adicional como invitaciones,
      carteles y carteles para el jardín. Los detalles de cada uno de estos
      eventos se pueden encontrar en{' '}
      <a href="/es/curso">godlovesyoutour.com/es/curso/</a>.
    </p>
  </div>
);

export default confirmationCopyIYC;
