const citiesInfo = {
  cityList: [
    {
      title: 'Brownsville, TX',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'Ministerio Restauracion y Poder',
          address: '4150 Jaime Zapata Ave, Brownsville, TX 78521',
          time: '7:00 PM CST',
          en: {
            date: 'Thursday, Jan. 4',
            link: '/en/brownsville/',
          },
          es: {
            date: 'Jueves 4 de enero',
            link: '/es/brownsville/',
          },
        },
      ],
      mainEvent: {
        location: 'Brownsville Sports Park',
        address: '1000 Sports Park Blvd., Brownsville, TX 78526',
        time: '5:00 PM CST',
        en: {
          date: 'Saturday, Feb. 24',
          link: '/en/brownsville/',
        },
        es: {
          date: 'Sábado 24 de febrero',
          link: '/es/brownsville/',
        },
      },
    },
    {
      title: 'McAllen, TX',
      metaInfo: '',
      clwc: [
        {
          location: 'The Family Church - McAllen Campus',
          address: '2322 North McColl Road, McAllen, TX 78501',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'The Family Church - McAllen Campus',
          address: '2322 North McColl Road, McAllen, TX 78501',
          time: '7:00 PM CST',
          en: {
            date: 'Friday, Jan. 5',
            link: '/en/mcallen/',
          },
          es: {
            date: 'Viernes 5 de enero',
            link: '/es/mcallen/',
          },
        },
      ],
      mainEvent: {
        location: 'Payne Arena',
        address: '2600 N 10th St., Hidalgo, TX 78557',
        time: '4:00 PM CST',
        en: {
          date: 'Sunday, Feb. 25',
          link: '/en/mcallen/',
        },
        es: {
          date: 'Domingo 25 de febrero',
          link: '/es/mcallen/',
        },
      },
    },
    {
      title: 'Laredo, TX',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'Iglesia Cristiana Misericordia – Main Campus',
          address: '4519 E. Del Mar Blvd., Laredo, TX 78041',
          time: '7:00 PM CST',
          en: {
            date: 'Friday, Jan. 12',
            link: '/en/laredo/',
          },
          es: {
            date: 'Viernes 12 de enero',
            link: '/es/laredo/',
          },
        },
      ],
      mainEvent: {
        location: 'Sames Auto Arena',
        address: '6700 Arena Blvd., Laredo, TX 78041',
        time: '6:30 PM CST',
        en: {
          date: 'Tuesday, Feb. 27',
          link: '/en/laredo/',
        },
        es: {
          date: 'Martes 27 de febrero',
          link: '/es/laredo/',
        },
      },
    },
    {
      title: 'Eagle Pass, TX',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'Roca Firme Christian Community Church',
          address: '3374 E. Main St., Eagle Pass, TX 78852',
          time: '7:00 PM CST',
          en: {
            date: 'Thursday, Jan. 11',
            link: '/en/eagle-pass/',
          },
          es: {
            date: 'Jueves 11 de enero',
            link: '/es/eagle-pass/',
          },
        },
      ],
      mainEvent: {
        location: 'Maverick County Amphitheater',
        address: 'Maverick County Lake Jogging Trail, Eagle Pass, TX 78852',
        time: '6:30 PM CST',
        en: {
          date: 'Wednesday, Feb. 28',
          link: '/en/eagle-pass/',
        },
        es: {
          date: 'Miércoles 28 de febrero',
          link: '/es/eagle-pass/',
        },
      },
    },
    {
      title: 'Del Rio, TX',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'Living Stone Worship Center',
          address: '190 Western Drive, Del Rio, TX 78840',
          time: '7:00 PM CST',
          en: {
            date: 'Wednesday, Jan. 10',
            link: '/en/del-rio/',
          },
          es: {
            date: 'Miercoles 10 de enero ',
            link: '/es/del-rio/',
          },
        },
      ],
      mainEvent: {
        location: 'Del Rio High Rams Stadium Parking Area',
        address: 'Wildcat Drive, Del Rio, TX 78840',
        time: '6:30 PM CST',
        en: {
          date: 'Thursday, Feb. 29',
          link: '/en/del-rio/',
        },
        es: {
          date: 'Jueves 29 de febrero',
          link: '/es/del-rio/',
        },
      },
    },
    {
      title: 'Presidio, TX',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          en: {
            location: 'TBA',
          },
          es: {
            location: '',
          },
          en: {
            addr: 'TBA',
          },
          es: {
            location: '',
          },
          location: 'Iglesia Restauración La Cosecha',
          address:
            'Justo Sierra 522, Linda Vista, 32885 Ojinaga, Chihuahua, Mexico',
          time: '7:00 PM CST',
          en: {
            date: 'Tuesday, Jan. 16',
            comment: '*Worship will be led by Juan Saa.',
            link: '/en/presidio/',
          },
          es: {
            date: 'Martes 16 de enero',
            comment:
              '*El tiempo de alabanza y adoración será dirigido por Juan Saa.',
            link: '/es/presidio/',
          },
        },
        {
          en: {
            location: 'TBA',
          },
          es: {
            location: '',
          },
          en: {
            addr: 'TBA',
          },
          es: {
            location: '',
          },
          location: 'Saint George Hall',
          address: '113 E. El Paso St., Marfa, TX 79843',
          time: '7:00 PM CST',
          en: {
            date: 'Wednesday, Jan. 17',
            link: '/en/presidio/',
          },
          es: {
            date: 'Miercoles 17 de enero',
            link: '/es/presidio/',
          },
        },
      ],
      mainEvent: {
        location: 'Manuel O. Hernandez Memorial Stadium',
        address: '800 O Reilly Street, Presidio, TX 79845',
        time: '5:00 PM CST',
        en: {
          date: 'Saturday, March 2',
          link: '/en/presidio/',
        },
        es: {
          date: 'Sábado 2 de marzo',
          link: '/es/presidio/',
        },
      },
    },
    {
      title: 'El Paso, TX',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'Harvest Christian Center',
          address: '1345 New Harvest Pl., El Paso, TX 79912',
          time: '7:00 PM MST',
          en: {
            date: 'Thursday, Jan. 18',
            link: '/en/el-paso/',
          },
          es: {
            date: 'Jueves 18 de enero',
            link: '/es/el-paso/',
          },
        },
      ],
      mainEvent: {
        location: 'Don Haskins Center, UTEP',
        address: '151 Glory Rd., El Paso, TX 79902',
        time: '4:00 PM MST',
        en: {
          date: 'Sunday, March 3',
          link: '/en/el-paso/',
        },
        es: {
          date: 'Domingo 3 de marzo',
          link: '/es/el-paso/',
        },
      },
    },
    {
      title: 'Tucson, AZ',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'Calvary Tucson Church – East Campus',
          address: '8711 E. Speedway Boulevard, Tucson, AZ 85710',
          time: '7:00 PM MST',
          en: {
            date: 'Tuesday, Jan. 23',
            link: '/en/tucson/',
          },
          es: {
            date: 'Martes 23 de enero',
            link: '/es/tucson/',
          },
        },
      ],
      mainEvent: {
        location: 'Kino Veterans Memorial Stadium',
        address: '2500 E. Ajo Way, Tucson, AZ 85713',
        time: '6:30 PM MST',
        en: {
          date: 'Tuesday, March 5',
          link: '/en/tucson/',
        },
        es: {
          date: 'Martes 5 de marzo',
          link: '/es/tucson/',
        },
      },
    },
    {
      title: 'Yuma, AZ',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'The Vertical Church',
          address: '3142 S. Arizona Ave, Yuma, AZ 85364',
          time: '7:00 PM MST',
          en: {
            date: 'Wednesday, Jan. 24',
            link: '/en/yuma/',
          },
          es: {
            date: 'Miercoles 24 de enero',
            link: '/es/yuma/',
          },
        },
      ],
      mainEvent: {
        location: 'Yuma County Fairgrounds',
        address: '2520 E. 32nd St., Yuma, AZ 85365',
        time: '6:30 PM MST',
        en: {
          date: 'Thursday, March 7',
          link: '/en/yuma/',
        },
        es: {
          date: 'Jueves 7 de marzo',
          link: '/es/yuma/',
        },
      },
    },
    {
      title: 'Chula Vista, CA',
      metaInfo: '',
      clwc: [
        {
          location: '',
          address: '',
          schedule: [
            {
              date: 'Saturday, September 1',
              time: '7:00 PM',
            },
            {
              date: 'Saturday, September 3',
              time: '6:00 PM',
            },
          ],
        },
      ],
      nightWorship: [
        {
          location: 'Ocean View Church – San Diego Campus',
          address: '2460 Palm Avenue, San Diego, CA 92154',
          time: '7:00 PM PST',
          en: {
            date: 'Thursday, Jan. 25',
            link: '/en/chula-vista/',
          },
          es: {
            date: 'Jueves 25 de enero',
            link: '/es/chula-vista/',
          },
        },
      ],
      mainEvent: {
        location: 'North Island Credit Union Amphitheatre',
        address: '2050 Entertainment Circle, Chula Vista, CA 91911',
        time: '5:00 PM PST',
        en: {
          date: 'Saturday, March 9',
          link: '/en/chula-vista/',
        },
        es: {
          date: 'Sábado 9 de marzo',
          link: '/es/chula-vista/',
        },
      },
    },
  ],
};

export default citiesInfo;
