import React from 'react';
//import Video from '@bgea/kaltura-player/dist/KalturaPlayer';

const confirmationCopyPTM = () => (
  <div>
    <p>
      Has ingresado correctamente tu solicitud para ser parte del Equipo de
      Oración. Muchas gracias. ¡Le damos gracias a Dios por ti! Sin embargo, no
      olvides que para que tu solicitud pueda ser considerada para su
      aprobación, tendrás que completar los siguientes pasos:
    </p>
    <ol>
      <li>
        <strong>
          Completa una verificación de antecedentes penales gratuita:
        </strong>{' '}
        Al enviar su solicitud para el equipo de oración, recibirás un correo
        electrónico de{' '}
        <a href="mailto:Webmaster@billygraham.email">
          Webmaster@billygraham.email
        </a>
        , que contiene los enlaces a una verificación de antecedentes gratuita.
        Si tienes 18 años o más, haga clic en el enlace de su ciudad si eres
        ciudadano o residente permanente de los Estados Unidos. Haga clic en el
        enlace de México si eres ciudadano de México.
      </li>
      <li>
        <strong>Recomendación pastoral:</strong> Hemos enviado un correo
        electrónico al pastor o líder de iglesia que proporcionaste en tu
        solicitud. Esa persona deberá responder a ese correo electrónico lo
        antes posible. Te recomendamos ponerte en contacto en esa persona y
        pedirle que responda a la brevedad posible.
      </li>
    </ol>
    <p>
      Nota: Si eres menor de 18 años, es necesario que la Billy Graham
      Evangelistic Association reciba el consentimiento de tus padres o tutores
      legales para que puedas recibir tu kit de voluntario*, y para que puedas
      servir como miembro del equipo de oración el día del evento.
    </p>
    <p>
      Si resides en los Estados Unidos, tu kit de voluntario será enviado por
      correo postal directamente al domicilio que incluiste en tu solicitud. Si
      resides en México, tu kit de voluntario será enviado a tu iglesia local y
      recibirás una notificación con información para que sepas cuándo puedes
      pases a recogerlo.
    </p>
    <p>
      <strong>
        *Los kits de voluntario dejarán de enviarse para distribución el 8 de
        febrero de 2024. Los kits de voluntario para quienes residen en los
        Estados Unidos dejarán de enviarse el 19 de febrero de 2024.
      </strong>
    </p>
    <p>
      Si tienes alguna pregunta, llama al (+1) 888-958-6329 (de lunes a viernes
      de 8:30 a. m. a 4:30 p. m. ET).
    </p>
  </div>
);

export default confirmationCopyPTM;
