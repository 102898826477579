import { ChurchInterest } from '@bgea-js/form-templates';
import formCopyIYC from "../../../../src/content/es/involveYourChurch/formCopy";
import confirmationCopyIYC from "../../../../src/content/es/involveYourChurch/confirmationCopy";
import citiesES from "../../../../src/constants/citiesES";
import * as React from 'react';
export default {
  ChurchInterest,
  formCopyIYC,
  confirmationCopyIYC,
  citiesES,
  React
};