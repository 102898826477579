import React from 'react';
// import { StaticImage } from "gatsby-plugin-image"
import './NativeShare.scss';
import share_button from '../images/share.svg';

/* This componenet displays a share button for mobile devices that trigger their built in share functionality*/
const NativeShare = ({ share_url = window.location.href }) => {
  //This const checks if the component is being rendered in a browser and if not, the component does not render
  const isBrowser = typeof window !== 'undefined';

  //This function is run when the user clicks on the share button. This function triggers the share functionality of the device that is rendered. It shares the url of the page where this component is rendered
  const handleShare = () => {
    navigator.share({ url: share_url });
  };

  return (
    <div>
      {isBrowser && navigator.share && (
        <div className="native_share_div">
          <a id="native_share" onClick={handleShare} href="">
            <img src={share_button} alt="share button for device" />
            <p>Share</p>
          </a>
        </div>
      )}
    </div>
  );
};

export default NativeShare;
