import React from 'react';
import { useLocation } from '@reach/router';
import citiesInfo from '../constants/citiesInfo.js';

const PrayerWorship = () => {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];

  const Address = {
    en: 'Address:',
    es: 'Domicilio:',
  };

  const Date = {
    en: 'Date:',
    es: 'Fecha:',
  };

  const Time = {
    en: 'Time:',
    es: 'Tiempo:',
  };

  const Info = {
    en: 'More Details',
    es: 'Más información',
  };

  return (
    <div className="tour-dates-wrapper">
      <>
        {citiesInfo.cityList.map(({ title, nightWorship }) => {
          return (
            <div className="tour-info">
              <div className="city">
                <h3>{title}</h3>
              </div>
              <div className="details-container">
                {nightWorship.map((nw) => (
                  <div className="details">
                    <h4>{nw.location}</h4>
                    <p>
                      <strong>{Address[currentPathLanguage]} </strong>
                      {nw.address}
                      <br />
                      <strong>{Date[currentPathLanguage]} </strong>
                      {nw[currentPathLanguage].date}
                      <br />
                      <strong>{Time[currentPathLanguage]} </strong>
                      {nw.time}
                      <br />
                      <a className='link' href={nw[currentPathLanguage].link}>{Info[currentPathLanguage]}</a>
                      <br />
                      {nw?.[currentPathLanguage]?.comment}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default PrayerWorship;
