import React from 'react';

const formCopyIYC = (
  <div>
    <p>Querido Pastor:</p>
    <p>
      Gracias por tu interés en participar en la gira God Loves You Frontera
      Tour con Franklin Graham.
    </p>
    <p>
      Iglesias como la tuya juegan un papel vital en las comunidades y en la
      evangelización. Esperamos unir fuerzas contigo y tu iglesia a fin de que
      el Evangelio se siga difundiendo.
    </p>
    <p>
      Por favor, completa el siguiente formulario para que tu iglesia pueda
      obtener más información sobre cómo orar, invitar a aquellos que aún no
      conocen a Jesucristo y asistir al evento más cercano de la gira God Loves
      You Frontera Tour.
    </p>
  </div>
);

export default formCopyIYC;
