import React from 'react';

const missionStatementCopy = (
  <>
    <h2>Misión y Declaración de fe de la BGEA </h2>
    <h4>Misión</h4>
    <p>
      En seguimiento al trabajo de toda una vida de Billy Graham, la Billy
      Graham Evangelistic Association (BGEA) existe para apoyar y extender el
      llamado evangelístico de Franklin Graham por medio de la proclamación del
      Evangelio del Señor Jesucristo a todos, por todos los medios posibles, y
      para equipar a la iglesia y a otros para que hagan lo mismo.
    </p>
    <h4>Declaración de fe de la Billy Graham Evangelistic Association</h4>
    <ul>
      <li>
        Creemos que la Biblia es la Palabra de Dios, inspirada, infalible y
        autoritativa. 1 Tesalonicenses 2:13; 2 Timoteo 3:15-17; Juan 3:16.
      </li>
      <li>
        Creemos en un solo Dios, eternamente existente en tres personas: Padre,
        Hijo y Espíritu Santo. Mateo 28:19; Juan 10:30; Efesios 4:4-6.
      </li>
      <li>
        Creemos en la deidad del Señor Jesucristo, en su nacimiento virginal, en
        su vida sin pecado, en sus milagros, en su muerte vicaria y expiatoria
        mediante el derramamiento de su sangre en la cruz, en su resurrección
        corporal, en su ascensión a la diestra del Padre, y en su retorno
        personal en poder y gloria. Mateo 1:23; Juan 1:1-4 y 1:29; Hechos 1:11 y
        2:22-24; Romanos 8:34; 1 Corintios 15:3-4; 2 Corintios 5:21; Filipenses
        2:5-11; Hebreos 1:1-4 y 4:15.
      </li>
      <li>
        Creemos que todos los hombres, en todo lugar, están perdidos y enfrentan
        el juicio de Dios; que Jesucristo es el único camino para la salvación
        del hombre perdido y pecador; que el arrepentimiento del pecado y la fe
        en Jesucristo resulta en la regeneración por medio del Espíritu Santo.
        Lucas 24:46-47; Juan 14:6; Hechos 4:12; Romanos 3:23; 2 Corintios
        5:10-11; Efesios 1:7 y 2:8-9; Tito 3:4-7.
      </li>
      <li>
        Creemos en el ministerio actual del Espíritu Santo, que al morar en el
        cristiano lo capacita para vivir una vida piadosa. Juan 3:5-8; Hechos
        1:8, 4:31; Romanos 8:9; 1 Corintios 2:14; Gálatas 5:16-18; Efesios 6:12;
        Colosenses 2:6-10.
      </li>
      <li>
        Creemos en la resurrección tanto de los salvos como de los perdidos: los
        salvos para resurrección de vida eterna, y los perdidos para
        resurrección de condenación y castigo eternos. 1 Corintios 15:51-57;
        Apocalipsis 20:11-15.
      </li>
      <li>
        Creemos en la unidad espiritual de los creyentes en el Señor Jesucristo,
        y que todos los verdaderos creyentes son miembros de su cuerpo: la
        Iglesia. 1 Corintios 12:12, 27; Efesios 1:22-23.
      </li>
      <li>
        Creemos que el ministerio de la evangelización (compartir y proclamar el
        mensaje de que la salvación se obtiene solo por la gracia y por medio de
        la fe en Jesucristo) y el discipulado (ayudar a los seguidores de Cristo
        a madurar en su fe en Cristo) es una responsabilidad de todos los
        seguidores de Jesucristo. Mateo 28:18-20; Hechos 1:8; Romanos 10:9-15; 1
        Pedro 3:15.
      </li>
      <li>
        Creemos que el plan de Dios para la sexualidad humana se expresa
        únicamente dentro del contexto del matrimonio, y que Dios creó al hombre
        y la mujer como personas biológicas únicas, hechas para completarse
        mutuamente. Dios instituyó el matrimonio monógamo entre varón y mujer
        como el fundamento de la familia y la estructura básica de la sociedad
        humana. Por esta razón, creemos que el matrimonio es exclusivamente la
        unión de un varón genético y una mujer genética. Génesis 2:24; Mateo
        19:5-6; Marcos 10:6-9; Romanos 1:26-27; 1 Corintios 6:9.
      </li>
      <li>
        Creemos que debemos dedicarnos a la oración, al servicio a nuestro
        Señor, a su autoridad sobre nuestras vidas, y al ministerio de la
        evangelización. Mateo 9:35-38; 22:37-39, y 28:18-20; Hechos 1:8; Romanos
        10:9-15 y 12:20-21; Gálatas 6:10; Colosenses 2:6-10; 1 Pedro 3:15.
      </li>
      <li>
        Creemos que la vida humana es sagrada desde su concepción hasta su final
        natural, y que debemos preocuparnos por las necesidades físicas y
        espirituales de nuestros semejantes. Salmo 139:13; Isaías 49:1; Jeremías
        1:5; Mateo 22:37-39; Romanos 12:20-21; Gálatas 6:10.
      </li>
    </ul>

    <hr />

    <h2>
      DECLARACIÓN DE LIBERACIÓN DE RESPONSABILIDADES LEGALES Y ASUNCIÓN DE
      RIESGOS POR PARTE DEL VOLUNTARIO
    </h2>
    <p>
      Yo, el adulto firmante (VOLUNTARIO), en consideración de la oportunidad de
      servicio voluntario con la Billy Graham Evangelistic Association (BGEA),
      libre, voluntariamente, y tras una lectura cuidadosa del documento, acepto
      las condiciones de esta DECLARACIÓN DE LIBERACIÓN DE RESPONSABILIDADES
      LEGALES Y ASUNCIÓN DE RIESGOS POR PARTE DEL VOLUNTARIO. Al hacerlo,
      acepto, reconozco y afirmo lo siguiente:
    </p>
    <p>
      <strong>Condición de Voluntario/Términos de seguros</strong> Entiendo y
      reconozco que cualquier servicio realizado por el VOLUNTARIO será como
      voluntario, no como empleado de la BGEA. El VOLUNTARIO no tiene derecho a
      prestaciones de empleado ni de otro tipo por parte de la BGEA, como seguro
      médico o de accidentes, indemnización laboral o compensación por tareas
      realizadas u horas trabajadas. Entiendo que es responsabilidad del
      VOLUNTARIO proporcionar un seguro de salud, discapacidad, responsabilidad
      civil o accidente para cubrir al VOLUNTARIO por cualquier reclamación o
      daños que puedan resultar de cualquier lesión, enfermedad, muerte o daño a
      la propiedad que el VOLUNTARIO sufra durante su voluntariado con la BGEA.
    </p>
    <p>
      <strong>Asunción de riesgos/Liberación</strong> Reconozco que se me ha
      proporcionado suficiente oportunidad para entender las circunstancias y el
      entorno en el que se prestarán los servicios de voluntariado, así como
      para solicitar información adicional antes de prestar dichos servicios, y
      entiendo que dichas circunstancias y entorno pueden estar fuera del
      control de la BGEA y pueden presentar diversos riesgos, incluyendo, sin
      limitación, el riesgo de enfermedad (incluyendo COVID-19), lesiones
      físicas, daños a la propiedad, o incluso la muerte, como resultado de
      dichos servicios. Por la presente asumo todos los riesgos asociados con la
      realización de estas actividades por parte del VOLUNTARIO, y libero y
      eximo para siempre a la BGEA de toda responsabilidad, incluso por actos u
      omisiones negligentes, por reclamaciones o daños que el VOLUNTARIO pueda
      tener como resultado de las actividades del VOLUNTARIO con la BGEA, y
      cualquier reclamación o daño relacionado que surja de la selección de
      actividades por parte de la BGEA o el suministro de alimentos o transporte
      en relación con el voluntariado con la BGEA. Entiendo que la BGEA no es
      responsable de la seguridad o protección de los efectos personales del
      VOLUNTARIO y libero a la BGEA de toda responsabilidad por robo, daño o
      destrucción de la propiedad personal del VOLUNTARIO.
    </p>
    <p>
      <strong>Fotografías y videos del ministerio</strong> Reconozco y acepto
      que, durante mi voluntariado con la BGEA, las actividades del VOLUNTARIO
      pueden ser fotografiadas o grabadas en video. Por la presente doy mi
      consentimiento para el uso ilimitado por parte de la BGEA y/o su
      representante autorizado de fotografías o videos en los que aparezca el
      VOLUNTARIO, sin compensación adicional, y reconozco y acepto que no tengo
      ningún derecho de propiedad sobre dichas fotografías o vídeos. Por la
      presente libero a la BGEA, sus agentes y cesionarios de cualquier
      reclamación que el VOLUNTARIO pueda tener en relación con cualquier
      fotografía o video, incluyendo, sin limitación, cualquier reclamación
      derivada del derecho de publicidad, derecho a la intimidad, difamación y/o
      infracción de derechos de autor. Entiendo que cualquier obra que cree
      mientras preste mis servicios como VOLUNTARIO pertenecerá a la BGEA,
      incluidos el título, la propiedad y los derechos de autor de dichas obras.
    </p>
    <p>
      <strong>Atención médica de emergencia</strong> Por la presente doy mi
      consentimiento para que la BGEA proporcione u obtenga atención médica de
      emergencia o primeros auxilios en caso de que el VOLUNTARIO sufra
      cualquier enfermedad o accidente mientras realiza actividades de
      voluntariado. Por la presente libero, descargo y eximo de responsabilidad
      a la BGEA de cualquier reclamación, incluso por actos u omisiones
      negligentes, relacionados con la prestación de dicha atención médica de
      emergencia.
    </p>
    <p>
      <strong>Consentimiento</strong> Doy mi consentimiento para que la BGEA
      conserve los datos personales y la información de contacto del VOLUNTARIO,
      tanto en Estados Unidos de América como en mi país (si no es los Estados
      Unidos de América) y para que se comunique de vez en cuando con el
      VOLUNTARIO sobre su participación, así como sobre futuras actualizaciones
      y oportunidades en el ministerio. Sé que puedo acceder a la Política de
      Privacidad de la BGEA en español en BillyGraham.org/privacidad. También sé
      que, si más adelante tengo alguna preocupación sobre cómo la imagen o las
      grabaciones del VOLUNTARIO pueden ser/son utilizadas, puedo ponerme en
      contacto con la BGEA y solicitar un cambio; sin embargo, también soy
      consciente de que cualquier cosa ya divulgada sería difícil o incluso
      imposible de retirar de la circulación.
    </p>
    <p>
      <strong>Confidencialidad</strong> Si el VOLUNTARIO está expuesto o tiene
      acceso a información que la BGEA pueda considerar confidencial, acepto que
      dicha información confidencial seguirá siendo propiedad de la BGEA en todo
      momento y acepto que el VOLUNTARIO no revelará, informará, publicará,
      divulgará, transferirá ni utilizará, directa o indirectamente, de ningún
      modo, la información confidencial, excepto en los casos específicamente
      autorizados por la BGEA.
    </p>
    <p>
      <strong>Código de conducta</strong> Declaro a la BGEA que el Señor
      Jesucristo es el Salvador personal del VOLUNTARIO, lo que significa que el
      VOLUNTARIO es un testigo de Él delante de los demás, en todo momento, a
      través de las palabras y la conducta del VOLUNTARIO, incluso durante el
      servicio del VOLUNTARIO con la BGEA. También reconozco que la autorización
      que recibe el VOLUNTARIO para realizar servicio voluntario con la BGEA
      está condicionado al acuerdo previo del VOLUNTARIO con la Misión y
      Declaración de Fe de la BGEA, y la demostración continua por parte del
      VOLUNTARIO de una conducta apropiada que sea consistente con esas
      declaraciones, enseñanzas y principios bíblicos, y con las políticas,
      procedimientos y expectativas de la BGEA. El VOLUNTARIO no representará a
      ningún otro ministerio ni ningún otro interés personal mientras sirva como
      voluntario.
    </p>

    <h5>
      Declaro y garantizo que el VOLUNTARIO no tiene antecedentes penales que
      impliquen violencia, mala conducta sexual o fraude, y me comprometo a
      notificar a la BGEA si hay algún cambio en tal estado o si el VOLUNTARIO
      es acusado en un asunto penal de cualquier tipo.
    </h5>

    <p>
      Tanto la BGEA como el VOLUNTARIO pueden dar por terminado el papel del
      VOLUNTARIO en cualquier momento por cualquier motivo o sin motivo alguno.
    </p>

    <p>
      Entiendo, acepto y estoy de acuerdo con los términos y condiciones
      establecidos en esta DECLARACIÓN DE LIBERACIÓN DE RESPONSABILIDADES
      LEGALES Y ASUNCIÓN DE RIESGOS POR PARTE DEL VOLUNTARIO. Declaro y
      garantizo que tengo plena autoridad legal para ejecutar este DECLARACIÓN
      DE LIBERACIÓN DE RESPONSABILIDADES LEGALES Y ASUNCIÓN DE RIESGOS POR PARTE
      DEL VOLUNTARIO, y acepto indemnizar, defender y eximir de responsabilidad
      a la BGEA contra las reclamaciones de terceros.
    </p>
  </>
);

export default missionStatementCopy;
