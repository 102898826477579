const citiesES = {
  TXBR: {
    label: 'Brownsville, TX - 24 de febrero de 2024',
    crusadeCodeEventId: 123,
    SECodeEventId: 'TXBR',
  },
  TXMA: {
    label: 'McAllen, TX - 25 de febrero de 2024',
    crusadeCodeEventId: 124,
    SECodeEventId: 'TXMA',
  },
  TXLR: {
    label: 'Laredo, TX - 27 de febrero de 2024',
    crusadeCodeEventId: 125,
    SECodeEventId: 'TXLR',
  },
  TXEG: {
    label: 'Eagle Pass, TX - 28 de febrero de 2024',
    crusadeCodeEventId: 133,
    SECodeEventId: 'TXEG',
  },
  TXDR: {
    label: 'Del Rio, TX - 29 de febrero de 2024',
    crusadeCodeEventId: 126,
    SECodeEventId: 'TXDR',
  },
  TXPR: {
    label: 'Presidio, TX - 2 de marzo de 2024',
    crusadeCodeEventId: 132,
    SECodeEventId: 'TXPR',
  },
  TXEP: {
    label: 'El Paso, TX - 3 de marzo de 2024',
    crusadeCodeEventId: 127,
    SECodeEventId: 'TXEP',
  },
  AZTC: {
    label: 'Tucson, AZ - 5 de marzo de 2024',
    crusadeCodeEventId: 128,
    SECodeEventId: 'AZTC',
  },
  AZYM: {
    label: 'Yuma, AZ - 7 de marzo de 2024',
    crusadeCodeEventId: 130,
    SECodeEventId: 'AZYM',
  },
  CASD: {
    label: 'Chula Vista, CA - 9 de marzo de 2024',
    crusadeCodeEventId: 131,
    SECodeEventId: 'CASD',
  },
};

export default citiesES;
