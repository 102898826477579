const cities = {
  TXBR: {
    label: 'Brownsville, TX – Feb. 24, 2024',
    crusadeCodeEventId: 123,
    SECodeEventId: 'TXBR',
  },
  TXMA: {
    label: 'McAllen, TX – Feb. 25, 2024',
    crusadeCodeEventId: 124,
    SECodeEventId: 'TXMA',
  },
  TXLR: {
    label: 'Laredo, TX – Feb. 27, 2024',
    crusadeCodeEventId: 125,
    SECodeEventId: 'TXLR',
  },
  TXEG: {
    label: 'Eagle Pass, TX – Feb. 28, 2024',
    crusadeCodeEventId: 133,
    SECodeEventId: 'TXEG',
  },
  TXDR: {
    label: 'Del Rio, TX – Feb. 29, 2024',
    crusadeCodeEventId: 126,
    SECodeEventId: 'TXDR',
  },
  TXPR: {
    label: 'Presidio, TX – March 2, 2024',
    crusadeCodeEventId: 132,
    SECodeEventId: 'TXPR',
  },
  TXEP: {
    label: 'El Paso, TX – March 3, 2024',
    crusadeCodeEventId: 127,
    SECodeEventId: 'TXEP',
  },
  AZTC: {
    label: 'Tucson, AZ – March 5, 2024',
    crusadeCodeEventId: 128,
    SECodeEventId: 'AZTC',
  },
  AZYM: {
    label: 'Yuma, AZ – March 7, 2024',
    crusadeCodeEventId: 130,
    SECodeEventId: 'AZYM',
  },
  CASD: {
    label: 'Chula Vista, CA – March 9, 2024',
    crusadeCodeEventId: 131,
    SECodeEventId: 'CASD',
  },
};

export default cities;
