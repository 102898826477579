import React, { useState } from 'react';
import { useQuery } from '../hooks/useQuery';
import { MDXRenderer } from 'gatsby-plugin-mdx';

function ReceptionsList() {
  const [currentTab, setCurrentTab] = useState('');
  const data = useQuery();
  const list = data.receptions.nodes;

  return (
    <>
      <div className="reception-tabs" id="example-tabs">
        {list.map((reception, index) => (
          <button
            key={index}
            onClick={() => {
              window.open(reception.frontmatter.slug);
            }}
            className={
              reception.frontmatter.tab === currentTab
                ? `active ${reception.frontmatter.title}`
                : `${reception.frontmatter.title}`
            }
          >
            <p>{reception.frontmatter.region}</p>
            <p class="venue">{reception.frontmatter.event.date}</p>
          </button>
        ))}
      </div>
      <div className="receptionInfo">
        {list.map((reception, index) => {
          if (reception.frontmatter.tab === currentTab) {
            return (
              <>
                <h2>{reception.frontmatter.region}</h2>
                <div className="cityinfo" key={index}>
                  <div className="listinfo">
                    <p>
                      <strong>City: </strong>
                      {reception.frontmatter.event.title}
                      <br />
                      <strong>Date: </strong>
                      {reception.frontmatter.event.date}
                      <br />
                      <strong>Time: </strong>
                      {reception.frontmatter.event.time}
                      <br />
                      <strong>Location: </strong>
                      {reception.frontmatter.event.location}
                      <br />
                      <strong>Address: </strong>
                      {reception.frontmatter.event.address}
                    </p>
                  </div>
                  <>
                    <MDXRenderer>{reception.body}</MDXRenderer>
                  </>
                </div>
              </>
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
}

export default ReceptionsList;
