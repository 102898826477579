import React from 'react';

const emailSubscribeConfirmationES = () => (
  <div>
    <p>Recibe actualizaciones acerca de la gira God Loves You Frontera Tour.</p>
    <p>¡Gracias!</p>
    <p>Tu información fue recibida.</p>
  </div>
);

export default emailSubscribeConfirmationES;
