import React from 'react';

const formCopyGM = (
  <div>
    <p>
      ¡Gracias por tu interés en servir como parte de un grupo en la gira God Loves You Frontera Tour! Por favor, completa el siguiente formulario según las indicaciones de tu Líder de Grupo. En breve, recibirás en tu correo electrónico información adicional sobre voluntarios y actualizaciones.
    </p>
    <br />
  </div>
);

export default formCopyGM;
