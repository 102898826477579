import React from 'react';
import { useLocation } from '@reach/router';
import citiesInfo from '../constants/citiesInfo.js';

const TourDates = () => {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];

  const Address = {
    en: 'Address:',
    es: 'Domicilio:',
  };

  const Date = {
    en: 'Date:',
    es: 'Fecha:',
  };

  const Time = {
    en: 'Time:',
    es: 'Tiempo:',
  };

  const Info = {
    en: 'More Details',
    es: 'Más información',
  };


  return (
    <div className="tour-dates-wrapper">
      <>
        {citiesInfo.cityList.map(({ title, mainEvent }) => {
          console.log(mainEvent);
          return (
            <div className="tour-info">
              <div className="city">
                <h3>{title}</h3>
              </div>
              <div className="details-container">
                <h4>{mainEvent.location}</h4>
                <p>
                  <strong>{Address[currentPathLanguage]} </strong>
                  {mainEvent.address}
                  <br />
                  <strong>{Date[currentPathLanguage]} </strong>
                  {mainEvent[currentPathLanguage].date}
                  <br />
                  <strong>{Time[currentPathLanguage]} </strong>
                  {mainEvent.time}
                  <br />
                  <a className='link' href={mainEvent[currentPathLanguage].link}>{Info[currentPathLanguage]}</a>
                </p>
              </div>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default TourDates;
