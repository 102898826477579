import { GroupMemberVolunteer } from '@bgea-js/form-templates';
import cities from "../../../../src/constants/cities";
import formCopyGM from "../../../../src/content/es/groupMemberVolunteer/formCopy";
import confirmationCopyGM from "../../../../src/content/es/groupMemberVolunteer/confirmationCopy";
import missionStatementCopy from "../../../../src/content/es/missionStatementAndRelease";
import * as React from 'react';
export default {
  GroupMemberVolunteer,
  cities,
  formCopyGM,
  confirmationCopyGM,
  missionStatementCopy,
  React
};